import axios from "axios"
import { chatbotBackendUrl } from "../constant"
import { toggleLoader } from "../../components/api-loader"


export let requestCount = 0

const instance = axios.create({
  baseURL: chatbotBackendUrl(),
  headers: {
    "Content-Type": "application/json",
  },
})

instance.interceptors.request.use(
  config => {
    requestCount++
    toggleLoader(requestCount)
    // const token = getAccessToken()
    // if (token) {
    //   config.headers["Authorization"] = "Bearer " + token // for Spring Boot back-end
    //   //config.headers["x-access-token"] = token; // for Node.js Express back-end
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    requestCount--
    toggleLoader(requestCount)
    return res
  },
  async err => {
    requestCount--
    toggleLoader(requestCount)
    console.log(err.config)
    const originalConfig = err.config

    // if (
    //   originalConfig !== undefined &&
    //   originalConfig.url !== "/api/user" &&
    //   err.response
    // ) {
    //   // Access Token was expired
    //   if (err.response.status === 401 && !originalConfig._retry) {
    //     originalConfig._retry = true

    //     try {
    //       return instance(originalConfig)
    //     } catch (_error) {
    //       return Promise.reject(_error)
    //     }
    //   }
    // }

    return Promise.reject(err)
  }
)

export default instance
