import * as React from "react"
import { useState, useRef, Fragment, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import ApiLoader from "../components/api-loader"
import { InformationCircleIcon } from "@heroicons/react/outline"
import { getClimateReadiness } from "../services/api-endpoints"
import { getAccessToken, getUser } from "../services/auth"
import { authCallbackUrl, authClientId, backAuthServerUrl, frontClientUrl } from "../services/constant"

const ClimateReadinessPlayground = () => {
    const isBrowser = () => typeof window !== "undefined"

    const loginUrl =
    backAuthServerUrl() +
    "/oauth/authorize?response_type=code&client_id=" +
    authClientId() +
    "&redirect_uri=" +
    authCallbackUrl() +
    "&scope=read"

    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("0")
    const [climateReadinessLevel, setClimateReadinessLevel] = useState("Low performing home")
    const [isAuthorized, setIsAuthorized] = useState();
    
    function predictClimateReadiness(e) {
        e.preventDefault()
        const form = e.target;
        var formData = new FormData(form);
        
        let formObject = Object.fromEntries([...formData.entries()]);
        console.log(formObject)
        getClimateReadiness(formObject).then(res => {
            setMessage(res?.data?.data?.response?.message)
            setClimateReadinessLevel(res?.data?.data?.response?.climate_readiness_level)

            setOpen(true)
        })
    }

    function adminLogin () {
        window.localStorage.setItem('stateRedirectURL', frontClientUrl() + '/admin-panel')
        window.location.href = backAuthServerUrl() + "/oauth/sso-logout?callback_uri=" + backAuthServerUrl() + '/login';
    }

    useEffect(() => {
        setIsAuthorized(false)
        const authorizedEmails = ["akash@evitat.com.au", "srikanth@evitat.com.au", "sonja@evitat.com.au", "armin@evitat.com.au"]
        if(getUser()?.email != undefined && getAccessToken() != undefined && getAccessToken() != "") {
            if(authorizedEmails.includes(getUser()?.email)){
                setIsAuthorized(true)
            }
        }
        else{
            window.localStorage.setItem('stateRedirectURL', frontClientUrl() + '/climate-readiness-playground')
            window.location.replace(loginUrl)
        }
      },[]);
    return (
        <>
        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto w-full">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6 sm:w-full sm:max-w-sm">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    {/* <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    <InformationCircleIcon />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {message}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your climate readiness level out of 3 is: {climateReadinessLevel}
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    {isAuthorized === false && 
        <div className="overflow-hidden lg:relative">
          <div className="grid place-items-center content-center pt-4 sm:pt-4 lg:py-4 bg-white ">
            <span className="mt-1 text-3xl font-bold tracking-tight text-gray-900">401 UNAUTHORIZED</span>
            <span className="mt-1 text-sm tracking-tight text-gray-900">You are not authorized to access this page</span>
            <button className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400" onClick={() => adminLogin()} >Log in using correct credentials</button>
          </div>
        </div>
      }
      {
        isAuthorized === true && 
        <div className="overflow-hidden flex lg:relative items-center justify-center">
      <ApiLoader />
            <div className="mt-8 xl:mt-0 py-4 ">
              <h3 className="text-lg font-extrabold text-gray-900">
                Climate readiness prediction
              </h3>
              <form
                method="post"
                className="grid grid-flow-row-dense grid-cols-3"
                onSubmit={predictClimateReadiness}
              >
                <div className="col-span-10">
                    <label for="insulation_ceiling" className="block text-sm font-medium leading-6 text-gray-900">Ceiling insulation R value</label>
                        <div className="mb-4">
                            <select defaultValue={0} id="insulation_ceiling" name="insulation_ceiling" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3+</option>
                            </select>
                        </div>
                    
                    <label for="insulation_wall" className="block text-sm font-medium leading-6 text-gray-900">Wall insulation R value</label>
                        <div className="mb-4">
                            <select id="insulation_wall" name="insulation_wall" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3+</option>
                            </select>
                        </div>
                   
                    <label for="insulation_floor" className="block text-sm font-medium leading-6 text-gray-900">Floor insulation R value</label>
                        <div className="mb-4">
                            <select id="insulation_floor" name="insulation_floor" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3+</option>
                            </select>
                        </div>
                    <label for="infiltration_value" className="block text-sm font-medium leading-6 text-gray-900">Property infiltration value</label>
                        <div className="mb-4">
                            <select id="infiltration_value" name="infiltration_value" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={0}>0.2ACH</option>
                                <option value={1}>0.5ACH</option>
                                <option value={2}>1.0ACH</option>
                            </select>
                        </div>

                    <label for="curtains_quality" className="block text-sm font-medium leading-6 text-gray-900">Curtains quality</label>
                        <div className="mb-4">
                            <select id="curtains_quality" name="curtains_quality" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={"low"}>Low quality</option>
                                <option value={"high"}>High quality</option>
                            </select>
                        </div>

                    <label for="window_system_quality" className="block text-sm font-medium leading-6 text-gray-900">Window quality</label>
                        <div className="mb-4">
                            <select id="window_system_quality" name="window_system_quality" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={"poor"}>Poor</option>
                                <option value={"most_efficient"}>Most efficient</option>
                            </select>
                        </div>

                    <label for="average_appliances_star_rating" className="block text-sm font-medium leading-6 text-gray-900">Average star rating of appliances</label>
                        <div className="mb-4">
                            <select id="average_appliances_star_rating" name="average_appliances_star_rating" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={3}>3 stars</option>
                                <option value={4}>4 stars</option>
                                <option value={5}>5+ stars</option>
                            </select>
                        </div>
                        
                    <label for="efficient_heating_cooling" className="block text-sm font-medium leading-6 text-gray-900">Do you have efficient heating and cooling?</label>
                        <div className="mb-4">
                            <select id="efficient_heating_cooling" name="efficient_heating_cooling" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={"yes"}>RCAC</option>
                                <option value={"no"}>Gas heating</option>
                                <option value={"no"}>Other (inefficient)</option>
                            </select>
                        </div>
                        
                    <label for="gas_connection" className="block text-sm font-medium leading-6 text-gray-900">Do you have a gas connection?</label>
                        <div className="mb-4">
                            <select id="gas_connection" name="gas_connection" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>

                    <label for="hrvs_system" className="block text-sm font-medium leading-6 text-gray-900">Do you have any HRVS system?</label>
                        <div className="mb-4">
                            <select id="hrvs_system" name="hrvs_system" autocomplete="country-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </select>
                        </div>
                </div>

                <button type="submit" className="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none"> Predict </button>
              </form>
            </div>
          </div>
      }
        </>
      )
}

export default ClimateReadinessPlayground