import authApi from "./axios/auth-api"
import chatbotApi from "./axios/chatbot-api"
import gptApi from "./axios/gpt-api"

export const getPlanningAlertApplicationsData = () => {
  return authApi.get("api/v1/planningalerts_applications")
}

export const getUserHealth = () => {
  return authApi.get("api/v1/utility/is-valid-token")
}


export const sendEmail = data => {
  return authApi.post("api/v1/mail/send", JSON.stringify(data))
}

export const postSupplierOnboardingInfo = data => {
  return authApi.post("api/v1/seller/lead", JSON.stringify(data))
}

export const postProfessionalOnboardingForm = data => {
  return authApi.post("api/v1/professional/lead", JSON.stringify(data))
}

export const openAICurie_getAnswerFromModal = query => {
  return chatbotApi.get("api/curie/get_query_result?query=" + query)
}

export const openAIDavinci_getAnswerFromModal = query => {
  return chatbotApi.get("api/davinci/get_query_result?query=" + query)
}

export const renoGptGetResponse = query => {
  return gptApi.get("EGI/get_query_result?query=" + query)
}

export const renoAssistantGetResponse = (query, assistant_id) => {
  return gptApi.get("EGI/assistant/get_response?query=" + query + "&assistant_id=" + assistant_id)
}

export const getClimateReadiness = (data) => {
  return chatbotApi.post("api/predict-climate-readiness", JSON.stringify(data))
}


export const subscribeToNewsletter = data => {
  return authApi.post("api/v1/news-letter/subscribe", JSON.stringify(data))
}

export const deleteUserProfile = userData => {
  return authApi.post("api/v1/profile/delete", JSON.stringify(userData))
}

export const updateKnowledgeBaseFeedback = (id, feedbackData) => {
  return authApi.put(
    "api/v1/feedback/knowledge-base/" + id,
    JSON.stringify(feedbackData)
  )
}

export const postKnowledgeBaseFeedback = feedbackData => {
  return authApi.post(
    "api/v1/feedback/knowledge-base",
    JSON.stringify(feedbackData)
  )
}
